/*
File: Custom Css File
*/

    /* styles.css */

    $primary: #2fba8c;



 .auth-one-bg .bg-overlay {
      background-image: url(../../images/auth-one-bg.jpg);
      background-position: center;
      background-size: cover;
  }

  
  .trade-bg {
      background-image: url('../../images/tradeBanner.png'); /* Replace with your image path */
      background-repeat: no-repeat;
      background-size: contain; /* Adjust as needed */
      background-position: bottom center;
      padding-bottom: 50px; /* Adjust spacing to fit the image */
      @media (max-width: 575.98px) {
        padding-left: 50px;
    }
  }

  #tooltip {    
    padding: 8px;
    font-size: 12px;
    color: #bac3cd;
    line-height: 11px;
    pointer-events: none;
  }

  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px; /* Adjust width as needed */
    display: inline-block;
    vertical-align: middle;
  }

  .center-align {
    text-align: center;
  }

.mobile-hidden {
  display: none;
}  

.offcanvas.offcanvas-custom {
  --vz-offcanvas-width: 70vw;  /* Set it to 90% of the viewport width */
  min-width: 390px;            /* Set the minimum width to 420px */
}


.btn-close {
  z-index: 999;
}